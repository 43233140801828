// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-automata-jsx": () => import("./../../../src/pages/automata.jsx" /* webpackChunkName: "component---src-pages-automata-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-celcsoportnak-jsx": () => import("./../../../src/pages/celcsoportnak.jsx" /* webpackChunkName: "component---src-pages-celcsoportnak-jsx" */),
  "component---src-pages-egy-szazalek-jsx": () => import("./../../../src/pages/egy-szazalek.jsx" /* webpackChunkName: "component---src-pages-egy-szazalek-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kapcsolat-jsx": () => import("./../../../src/pages/kapcsolat.jsx" /* webpackChunkName: "component---src-pages-kapcsolat-jsx" */),
  "component---src-pages-modszerunk-jsx": () => import("./../../../src/pages/modszerunk.jsx" /* webpackChunkName: "component---src-pages-modszerunk-jsx" */),
  "component---src-pages-rolunk-jsx": () => import("./../../../src/pages/rolunk.jsx" /* webpackChunkName: "component---src-pages-rolunk-jsx" */),
  "component---src-pages-sikeres-jsx": () => import("./../../../src/pages/sikeres.jsx" /* webpackChunkName: "component---src-pages-sikeres-jsx" */),
  "component---src-pages-sikertelen-jsx": () => import("./../../../src/pages/sikertelen.jsx" /* webpackChunkName: "component---src-pages-sikertelen-jsx" */),
  "component---src-pages-tamogatas-jsx": () => import("./../../../src/pages/tamogatas.jsx" /* webpackChunkName: "component---src-pages-tamogatas-jsx" */),
  "component---src-pages-vallalatoknak-jsx": () => import("./../../../src/pages/vallalatoknak.jsx" /* webpackChunkName: "component---src-pages-vallalatoknak-jsx" */),
  "component---src-pages-visszajelzes-js": () => import("./../../../src/pages/visszajelzes.js" /* webpackChunkName: "component---src-pages-visszajelzes-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */)
}

